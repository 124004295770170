.back-to-top { 
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 19px;
    background: #ffa500;
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;   
    color: white;
    cursor: pointer;
    border-radius: 35px;
    border: none;
}

@media (max-width: 565px) {
  img {
    width: 20px
  }
}

@media (max-width: 400px) {
    img {
        width: 15px
    }
}

@media (max-width: 1000px) {
    .back-to-top{
    bottom: 40px;
    right: 40px;
    font-size: 19px;
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 565px) {
    .back-to-top{
      bottom: 30px;
      right: 30px;
      font-size: 14px;
      width: 30px;
      height: 30px;
    }
}

@media (max-width: 400px) {
    .back-to-top{
    bottom: 20px;
    right: 20px;
    font-size: 10px;
    width: 20px;
    height: 20px;
  }
}

.back-to-top:hover {
    background: #ffc800;
  }
  
  a:link {
    color: orange;
    background-color: transparent;
    text-decoration: none;
  }
  a:visited {
    color: orange;
    background-color: transparent;
    text-decoration: none;
  }
  a:hover {
    color: orange;
    background-color: transparent;
    text-decoration: underline;
  }
  a:active {
    color: orangered;
    background-color: transparent;
    text-decoration: underline;
  }

.faq-style-wrapper {
    text-align: left;
}

.faq-style-wrapper .faq-title {
    user-select: text;
}

.faq-style-wrapper .row-content-text {
    user-select: text;
    line-height: 1.6;
}

.faq-style-wrapper .faq-row {
  font-size: 13px;  
}

@media (max-width: 1000px) {
  .faq-style-wrapper .faq-row {
    font-size: 9px;  
  }
}

@media (max-width: 565px) {
  .faq-style-wrapper .faq-row {
    font-size: 7px;  
  }
}
